<template>
  <v-app id="inspire">
    <div v-if="$store.state.preventas.listPreventas" class="container-table-informes">
      <template>
        <div class="container-title-informe">
          <h3><i class="fas fa-ticket-alt pr-2"></i>Listado de preventas</h3>
        </div>
        <div>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              single-line
              hide-details
            ></v-text-field>
            <router-link :to="{ name: 'CrearPreventa'}">
              <v-btn color="green" title="Agregar" size="3" class="text-white ml-5"><span>Crear Preventa</span><i class="pl-3 fas fa-plus-circle"></i></v-btn>
            </router-link>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="$store.state.preventas.listPreventas.preventas"
            :search="search"
          >
            <template v-slot:items="props">
              <td class="text-xs-center" :key="index" v-for="(header, index) in headers">
                <div v-if="header.value != 'actions'">
                  {{ props.item[header.value] }}
                </div>
                <div v-else>
                  <router-link :to="{ name: 'ActualizarPreventa', params: {id_Preventa_Edit: props.item.id}}">
                    <v-btn fab small color="primary" title="Editar"><i class="fas fa-pencil-alt"></i></v-btn>
                  </router-link>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
      </template>
    </div>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      search: '',
      headers: [
        { align: "center", text: "ID", value: "id" },
        { align: "center", text: "NOMBRE", value: "nombre" },
        { align: "center", text: "EVENTO", value: "evento" },
        { align: "center", text: "APLICA A", value: "aplica" },
        { align: "center", text: "FECHA INICIO", value: "fecha_inicio" },
        { align: "center", text: "HORA INICIO", value: "hora_inicio" },
        { align: "center", text: "FECHA FIN", value: "fecha_fin" },
        { align: "center", text: "HORA FIN", value: "hora_fin" },
        { align: "center", text: "DESCUENTO PRECIO", value: "descuento_precio" },
        { align: "center", text: "DESCUENTO SERVICIO", value: "descuento_servicio" },
        { align: "center", text: "ACTIVA", value: "activo" },
        { align: "center", text: "VISIBLE", value: "visible" },
        { align: "center", text: "ACTIVADO PARA VENTA EN LINEA", value: "venta_linea" },
        { align: "center", text: "AUTOMÁTICA", value: "automatica" },
        { align: "center", text: "FECHA CREACIÓN", value: "created_at" },
        { align: "center", text: "ACCIONES", value: "actions" },
      ]
    }
  },
  async mounted(){
    this.$store.state.preventas.listPreventas = null;
    await this.$store.dispatch('preventas/getListPreventa');
    document.title = 'Listar preventas - TicketShop'
  }
}
</script>
<style>
  .filter-informe-eventos{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 5px 1px rgb(219, 219, 219);
  }
  .v-table__overflow{
    max-height: none !important;
    overflow-y: hidden !important;
  }
</style>